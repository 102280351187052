<template>
  <v-container>
    <h2 class="mb-5">
      {{ $t('langkey.footer-download-header') }}
    </h2>
    <p
      class="mb-5"
      v-html="$t('langkey.footer-download-message')"
    />

    <a
      href="/Tyres-N-Parts_Presseinformation.pdf"
      target="_blank"
      class="d-flex"
    >
      <icon-pdf class="mr-3" />
      <p v-html="$t('langkey.download-press-news-181121')" />
    </a>
    <br>
    <a
      href="/TyresNParts_Unser_Angebot.pdf"
      target="_blank"
      class="d-flex"
    >
      <icon-pdf class="mr-3" />
      <p v-html="$t('langkey.download-unser-angebot-011221')" />
    </a>
    <br>
    <a
      href="/TNP_Jahresstart_DE.pdf"
      target="_blank"
      class="d-flex"
    >
      <icon-pdf class="mr-3" />
      <p>
        <b>Tyres-N-Parts Pressemitteilung vom 03.02.22</b>
        <br>
        "Digitale B2B-Handelsplattform Tyres-N-Parts legt „heißen“ Start hin – Kundenorientierung im Fokus"
      </p>
    </a>
  </v-container>
</template>

<script>
import IconPdf from '@/components/icons/IconPdf';
export default {
  components: {
    IconPdf,
  },
};
</script>
